.topper {
  position: fixed;
  bottom: 75px;
  right: 20px;
  z-index: 20;
    cursor: pointer;

  a {

    i {
      transition: .2s linear all;

      display: inline-block;
      background: $gray-light;
      color: #ffffff;
      border-radius: 50%;
      font-size: 14px;
      padding: 8px 9px;

      line-height: 125%;

      &:hover, &:focus, &:active {
          background: $color5;
      }
    }
  }
}
