.date.form-control[readonly]{
  background-color: inherit;
}

.bootstrap-datetimepicker-widget table td {

  &.weekend {
    background-color: $gray-lighter;
  }

  &.highlight {
    background-color: $color5;
    color: white;

    &.new, &.old{
      color:#ddd;
    }
  }

  &.today {
    //border: 1px solid $brand-primary;
  }
}

.events-affix-sidebar {
    &.affix{
        position: fixed;
        top: $navbar-height + $padding-large-vertical
    }


    &.affix-top{
        position: static;
    }

    &.affix-bottom{
        position:absolute;
    }
}


@media (max-width: 768px) {
    .events-affix-sidebar {
        &.affix, &.affix-top,  &.affix-bottom{
            width: auto;
            position: inherit;
            top: inherit;
        }
    }
}


.event-list-item {

  padding: 20px 15px; // more space between events

  // alternating color
  &:nth-child(even):not(.disabled) {
    background: lighten($gray-lighter, 6%);
  }

  &.disabled a {
    color: $gray;
  }

  hr { margin: 0.5em 0;}
}
