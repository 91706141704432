
.avatar {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  background-color: darken($body-bg, 5%);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  font-size: 1.6em;
  line-height: 1.2em;

  margin: 1px 0;
  &--left{
    margin-right: 0.3em;
  }
  //margin-left: 0.3em;

  color: transparentize(darken($color5, 30%), .6);

  &--initials{
    width: 100%;
    font-family: 'Roboto', serif;
    line-height: 2.1em;
    font-size: inherit; // can't have font-size inherit on line above, so need to override
    text-rendering: auto; // optimizelegibility throws things off #1094
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &--img {
    border-radius: 50%;
  }

  &--premium {
    border: 1px solid transparentize(darken($color5, 30%), .4);
    @include box-shadow(0px 0 14px 2px transparentize(darken($color5, 30%), .5));

    color: transparentize(darken($color5, 30%), .3);
  }

  .navbar &, &--small, &--small-no-margin {

    width: 30px;
    max-height: 30px;
    min-height: 30px;
    min-width: 30px;
    font-size: 14px;
    line-height: 1.2em;

    margin-top: -10px;
    margin-bottom: -10px;
      margin-right: 5px;

    @include box-shadow(0px 0 5px 2px transparentize(darken($color5, 30%), .6));
  }
  &--small-no-margin {
      margin-top: 0;
      margin-bottom: 0;
  }

  & span.fa {
    vertical-align: -40%;
  }
}

.profile-badge {
  padding: 0 0 0 0;

  &--image{
    height: 120px;
    background-size: cover;
    background-image: url('/theme/image/profile-header');
    background-color: $color5;
    @include border-top-radius($border-radius-base);
  }

  &--avatar-button {
    margin-top: -35px;
    margin-bottom: 20px;
  }

  & .avatar{
    width: 100px;
    max-height: 100px;
    min-height: 100px;
    min-width: 100px;
    margin-top: -50px;
    margin-bottom: 10px;
    border: 3px solid #fff;
    font-size: 3.3em;
  }

  // list with numbers
  & ul {
    list-style: none;
    padding: 0;
    padding-top: 25px;
    & li {

      display: inline-block;
      padding: 0 10px;
      border-right: 1px solid #d4dbe0;
      &:last-child {
        border-right: 0;
      }
      & h5{
        font-weight: bolder;
      }
    }
  }
}
