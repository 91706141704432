
@mixin reference-variant($color) {
 // color: lighten($color, 50%);
  background-color: $color;
  color: transparentize(text-contrast($color), 0.1);
  background: linear-gradient(to bottom, $color, darken($color, 8%));

  &:before {
    background: transparentize(lighten($color, 15%), 0.4);
    box-shadow: 0 1px 1px darken($color, 3%), -1px 1px 1px darken($color, 6%);
  }

  .reference__columns--active-1 .reference__columns__column--1, .reference__columns--active-2 .reference__columns__column--2, .reference__columns--active-3 .reference__columns__column--3 {
    background-color: transparentize(darken($color, 10%), 0.7);
  }
}

.reference{


  @include reference-variant(#eee);

  // fix positioning on Safari when 2 references are on 1 line
  // and title of the book is longer on one of the refs
  display: inline-table;
  //display: inline-block;

  min-height: 165px;
  height:165px;
  width: 120px;
  line-height: 2em;
  padding: 30px 0 3px 8px;
  font-size: 0.9em;


  //http://nicolasgallagher.com/pure-css-folded-corner-effect/

  //color: lighten($page-color, 50%);
  //background-color: $page-color;
  overflow: hidden; // hide the shadow for the page corner
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 16px 16px 0;
    border-style: solid;
    border-color: white white transparent transparent;
    //background: lighten($page-color, 15%);
    //box-shadow: 0 1px 1px darken($page-color, 5%), -1px 1px 1px darken($page-color, 5%);
    /* Firefox 3.0 damage limitation */
    display: block; width: 0;
    z-index:2;
  }

  &__columns{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    min-height: inherit;
    z-index: 1;


    &__column{
      width:33.33%;
      float:left;
      height: inherit;
      min-height: inherit;
    }

    //&--active-1 &__column--1, &--active-2 &__column--2, &--active-3 &__column--3{
    // // background-color: transparentize(darken($page-color, 10%), 0.7);
    //}

  }

  &__book{
    position:relative;
    width:100%;
    z-index: 2;
  }

}


.reference--lambert-2016{
  @include reference-variant(#018078)
}

.reference--lambert-2016-additif{
  @include reference-variant(darken(#018078, 7%))
}

.reference--heliot-2017{
  @include reference-variant(#539024)
}

.reference--lambert-2017-generiques{
  @include reference-variant(#ffe6b6);
}

.reference--lambert-2018{
  @include reference-variant(#e99300)
}

.reference--lambert-2019{
  @include reference-variant(#656320)
}

.reference--lambert-2019-generiques{
  @include reference-variant(#e891b4);
  color: transparentize(white, 0.1);
}

.reference--lambert-2020{
    @include reference-variant(#dcdd00)
}

.reference--lambert-2021{
    @include reference-variant(#7766BB);
    color: transparentize(white, 0.1);
}

.reference--lambert-2021-generiques{
    @include reference-variant(#ff8e5a);
    color: transparentize(white, 0.1);
}

.reference--lambert-2021-mousseux{
    @include reference-variant(#a24b9e);
}

.reference--heliot-2021{
    @include reference-variant(#4f8bc7);
    color: transparentize(white, 0.1);
}

.reference--lambert-2022{
    @include reference-variant(#ff6d04);
    color: transparentize(white, 0.1);
}

.reference--lambert-2023{
    @include reference-variant(#2c912c);
    color: transparentize(white, 0.1);
}

.reference--mignon-pierre-2023, .reference--mignon-pierre-2024{
    @include reference-variant(#2c74d2);
    color: transparentize(white, 0.1);
}

.reference--lambert-2023-generiques{
    @include reference-variant(#4bb942);
}

.reference--lambert-2024{
    @include reference-variant(#703f20);
    color: transparentize(white, 0.1);
}

.reference--de-venoge-2024{
    @include reference-variant(#10335d);
    color: transparentize(white, 0.1);
}

.reference--lambert-2025{
    @include reference-variant(#ff6d04);
    color: transparentize(white, 0.1);
}

.reference--petit-journal{
  @include reference-variant(#fff5dd)
}

.reference--jacques-constant, .reference--jacques-constant-additif-2007-1, .reference--jacques-constant-additif-2007-2, .reference--jacques-constant-additif-2008-3, .reference--jacques-constant-additif-2010-4, .reference--jacques-constant-additif-2010-5 {
  @include reference-variant(#222222)
}

.reference--caps-2009{
    @include reference-variant(rgba(71, 202, 255, 0.3))
}

.reference--nr{
  @include reference-variant(#eee)
}
.reference--producer, .reference--empty{
  padding: 30px 3px 3px 3px;
}
