
.banner {
    margin-bottom: $line-height-computed;
    background-color: $panel-bg;
    border: 1px solid transparent;
    border-radius: $panel-border-radius;
    padding: $panel-border-radius;
    @include box-shadow(0 1px 1px rgba(0,0,0,.05));

    text-align: center;
}

// Panel contents
.banner-body {
    padding: 0;
    @include clearfix;
}

.banner-img{
    object-fit: contain;
    width:100%;
}
