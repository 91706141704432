
.list-pagination {
  & .pagination {
    margin: 0;
  }
  margin-bottom: 20px;
}

.panel > .list-group--max-height {

    max-height: 480px;
    overflow-y: scroll;
    //margin-bottom: -$panel-body-padding;
    //margin-top: -$panel-body-padding;
}

.panel > .panel-body--list-preview {
  max-height: 200px;
  overflow-y: hidden;
  overflow-x: hidden;
}

// indent a list-group-item to create a tree like menu
.list-group-item-indent{
  padding-left: 30px;
}

.list-group-item > a {
    color: inherit;
}

.list-group--search-user-results{

  & .list-group-item--username {

    font-size: 17px;
  }

  & .list-group-item--body{
    margin-top: 7px;

    small,
    .small {
      font-size: 75%;
    }
  }
}

.list-group-separator{
  background-color: #fafafa;
}

.list-group-item-extra-list {
    & > .input-group {
        margin-left: -5px;
        max-width: 110px;
    }

    & > .list-title {
        margin-left: 10px;
        line-height: 3rem;
    }
}
