/* latin */
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap);

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), local('sans-serif'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$font-family-sans-serif: 'Roboto', sans-serif;

/* Coolors Exported Palette - coolors.co/edf4fc-93a3b1-7f8787 -444444-ffbb00
https://coolors.co/f8f9ea-c9e3ac-90be6d-37371f-ffbb00
https://coolors.co/f9f8f8-eb9486-7e7f9a-272838-f3de8a
https://coolors.co/dfe0e2-a2aebb-75abbc-071013-23b5d3
https://coolors.co/f7f7ff-bdd5ea-577399-495867-fe5f55
https://coolors.co/0b132b-1c2541-3a506b-5bc0be-6fffe9
--56a3a6--
Alice Blue
Cadet Grey
Battleship Gray
Arsenic
Selective Yellow
*/
/* RGB */
$color1: lighten(#edf4fc, 2%); //rgba(237, 244, 252, 1);
$color2: #1c2541; //rgba(147, 163, 177, 1);
$color3: #577399; //rgba(124, 136, 136, 1);
$color4: #444444; //rgba(68, 68, 68, 1);
$color5: #5bc0be; //rgba(255, 187, 0, 1);
$brand-primary: $color3;
$brand-success: $color5 !default;
$brand-info: #cddc39 !default;

$border-radius-base: 5px;

$font-size-base: 14px !default;

$line-height-base:        1.498571429 !default; // 20/14
$font-size-h1: floor(($font-size-base * 2.35)) !default; // ~36px

$body-bg: #e0f2f1; //$color1;

$gray-base: $color4 !default;
$gray-darker: lighten($gray-base, 3.5%) !default; // #222
$gray-dark: lighten($gray-base, 10%) !default; // #333
$gray: lighten($gray-base, 25%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 65.5%) !default; // #eee

// input placeholder color
$input-color: #444;
$input-color-placeholder: lighten($gray-base, 55%) !default;
$input-border-focus: $color3;

$navbar-height: 45px !default;

$navbar-default-color: #fff !default;
$navbar-default-bg: $color5 !default;
$navbar-default-border: darken($navbar-default-bg, 6.5%) !default;

$navbar-default-link-color: lighten($navbar-default-bg, 35%) !default;
$navbar-default-link-hover-color: #fff !default;
$navbar-default-link-hover-bg: transparent !default;
$navbar-default-link-active-color: #eee !default;
$navbar-default-link-active-bg: darken($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-color: lighten($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-bg: transparent !default;

// Navbar toggle
$navbar-default-toggle-hover-bg: lighten($navbar-default-bg, 5%) !default;
$navbar-default-toggle-icon-bar-bg: $navbar-default-link-color !default;
$navbar-default-toggle-border-color: transparent !default;

$nav-link-hover-bg: $gray-lighter !default;

$nav-disabled-link-color: $gray-light !default;
$nav-disabled-link-hover-color: $gray-light !default;

$footer-height: 275px !default;

// modal
$modal-md:                    800px !default; // a bit wider for the extra lists

@import "vendor/bootstrap";
@import "vendor/font-awesome";
@import "vendor/croppie";
@import "vendor/bootstrap_datetimepicker";
@import "vendor/bootstrap-switch";
@import "vendor/select2";

@import "functions";
@import "animations";
@import "misc";

@import "components/avatar";
@import "components/banners";
@import "components/badges";
@import "components/cap";
@import "components/colors";
@import "components/dragging";
@import "components/reference";
@import "components/panels";
@import "components/lists";
@import "components/typeahead";
@import "components/landingpage";
@import "components/footer";
@import "components/back_to_top";
@import "components/messenger";
@import "components/map";
@import "components/events";
@import "components/cookie_consent";
@import "components/pagination";
@import "components/margin-padding";

/*
 * Base structure
 */
html {
  position: relative;
  min-height: 100%;
}

/* Move down content because we have a fixed navbar that is 50px tall */
body {
  padding-top: $navbar-height;
  background-image: radial-gradient(circle at center top, #5bc0bb22, $body-bg, $body-bg 100%);
}

textarea {
  resize: vertical;
}

address {
  margin-bottom: 0;
}

iframe {
  border: 0;
}

// when linked using anchors
// eg when using the search
[id]:target:not(.tab-pane) {
  animation: highlight 4s ease;
}

// fix anchor scrolling due to fixed navbar
*[id]:before {
 // display: block;
  content: " ";
  margin-top: -$navbar-height px; // Set the Appropriate Height
  height: $navbar-height px; // Set the Appropriate Height
  visibility: hidden;
}

/*
 * Top navigation
 * Hide default border to remove 1px line.
 */

/*
 * Sidebar
 */

/* Hide for mobile, show later */
.sidebar {
  position: fixed;
  top: $navbar-height;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: block;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  background-color: $color2;
  color: $color4;
  border-right: 1px solid ligthen($color2, 10%);
}

/* Sidebar navigation */
.nav-sidebar {
  margin-right: -21px; /* 20px padding + 1px border */
  margin-bottom: 20px;
  margin-left: -20px;
}

.nav-sidebar > li > a {
  padding-right: 20px;
  padding-left: 20px;
  color: lighten($color3, 30%);

  &:hover {
    background-color: lighten($color3, 10%);
    color: #fff;

  }
}

.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
  color: #fff;
  background-color: lighten($color3, 10%);
}

/*
* NAVBAR LOGO
*/

.navbar-default .navbar-brand {
    margin-right: 5px;
    padding: 0;
    line-height: 2.5em;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    color: transparentize(#000, 0.5);
}

.navbar-brand--image {
  height: 100%;
  padding: 5px;
  width: auto;
  float: left;
}

@media(min-width: $screen-md-min) {
    .navbar-brand--image {
        height: 65%;
        margin-top: 7px;
        padding: 0 4px 0 8px;
    }
}
.navbar .navbar-form {
  padding: 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar-nav>li:not(:last-child) {
  border-right: 1px solid $navbar-default-border;
}

.navbar--unread-messages {
  padding: 6px 3px;
  margin-right: 0
}

/*
 * Main content
 */

.main {
  padding: 20px 15px 35px;
  &--full {
    padding: 0;
  }
}

.main .page-header {
  margin-top: 0;
}

.page-header {
  padding-bottom: (($line-height-computed / 2) - 1);
  margin: ($line-height-computed) 0 $line-height-computed/2;
  border-bottom: 0;
}

.alert--floating {
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
}

// alerts need to stick to eachother
.alert + .alert {
  margin-top: -$line-height-computed;
}

.blockquote{


  padding: 10px;
  //margin: 0 0 $line-height-computed;
  //font-size: $blockquote-font-size;
  border-left: 5px solid $blockquote-border-color;
}

.container-fluid--maxwidth {
    max-width: $container-large-desktop;
}

.label{
    font-weight: normal;
}

.opacity-50{
    opacity: 0.5;
}
