// make the search box wider

.pagination {

    .jump-to-container
    {
        padding: 2px 11px;

        > input{
            @extend .form-control;
            display: inline-block;
            width:  70px;
            height: 28px;

            padding: 3px 12px;
        }
    }

}
