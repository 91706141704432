@mixin color-variant($color) {
  background-color: $color;
  background: radial-gradient( lighten($color, 10%), darken($color, 5%) );
  color: transparentize(text-contrast($color), 0.3);
}

.color {
  display: inline-block;
  padding: 3px 7px;
  font-size: $font-size-small;
  font-weight: $badge-font-weight;
  color: $badge-color;
  line-height: $badge-line-height;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: $badge-bg;
  border-radius: $badge-border-radius;

  border: 1px solid rgba(0, 0, 0, 0.2);
  min-width: $badge-border-radius *2;
  min-height: $badge-border-radius *2;
  &:before{
    content: ' ';
  }
}
li.color{
  margin: 2px 3px;
}
// align colors evenly on the correction tab
.color-input-wrapper {
  min-width: 125px;
  &+&{
    margin:0;
  }
}
.checkbox-inline-wrapper {
    padding-right: 25px;
    &+&{
        margin:0;
    }
}

.color-polychrome {
  background: linear-gradient(to left, #8a188c , #9b0078 ,#d50e44 ,#ffb440, #e5de53, #67e372 , #009ed6 , #4f61d3 , #8a188c );
}

.color-blanc {
  @include color-variant(#FFF);
  background:none;
}

.color-jaune {
  @include color-variant(#ffee33)
}

.color-noir {
  @include color-variant(#000000)
}

.color-gris {
  @include color-variant($gray-light)
}

.color-creme {
  @include color-variant(#ffeebb)
}

.color-or {
  @include color-variant(#e7ba30)
}

.color-orange {
  @include color-variant(#ff9430)
}

.color-bleu {
  @include color-variant(#336699)
}

.color-bordeaux {
  @include color-variant(#990000)
}

.color-rose {
  @include color-variant(#ea9198)
}

.color-marron {
  @include color-variant(#816551)
}

.color-rouge {
  @include color-variant(#e60038)
}

.color-vert {
  @include color-variant(#008100)
}

.color-violet {
  @include color-variant(#c94093)
}


.text-orange {
    color: #ff9430;
}



.list-orange {
    color: #ff9430;
}

.list-red {
    color: #e60038;
}

.list-blue {
    color: #336699;
}

.list-green {
    color: #008100;
}

.list-yellow {
    color: #ffee33;
}
