.cookie-consent{
  @extend .alert;
  @include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
  @include box-shadow(0 0 20px rgba(0,0,0,.07));

  margin: 20px;
  max-width: 550px;
  //border-radius: 0;
  padding: 10px 30px;
  text-align: center;

  &--message, &--image, &--button{
    display: inline-block;
  }
  &--message {
    padding-bottom:5px;
  }
  &--image {
    width: 40px;
    color: $alert-info-text;
  }
  &--button {
    min-width: 150px;
  }
}
