// @import "/node_modules/croppie/croppie.css";

//.croppie-container {
//  padding: 30px;
//}

.croppie-container .cr-image {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0 0;
  max-width: none;
}

.croppie-container .cr-boundary {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  z-index: 1;
}

.croppie-container .cr-viewport {
  position: absolute;
  //border: 1px solid #fff;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, 0.7);
  z-index: 0;
}
.croppie-container .cr-vp-circle {
  border-radius: 50%;
}
.croppie-container .cr-overlay {
  z-index: 1;
  position: absolute;
  cursor: move;
}
.croppie-container .cr-slider-wrap {
  width: 75%;
  margin: 0 auto;
  margin-top: 25px;
  text-align: center;
}
//.croppie-result {
//  position: relative;
//  overflow: hidden;
//}
//.croppie-result img {
//  position: absolute;
//}
.croppie-container .cr-image,
.croppie-container .cr-overlay,
.croppie-container .cr-viewport {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

/*************************************/
/***** STYLING RANGE INPUT ***********/
/*************************************/
/*http://brennaobrien.com/blog/2014/05/style-input-type-range-in-every-browser.html */
/*************************************/

.cr-slider {
  -webkit-appearance: none;/*removes default webkit styles*/
  /*border: 1px solid white; *//*fix for FF unable to apply focus style bug */
  width: 300px;/*required for proper track sizing in FF*/
  max-width: 100%;
  //padding-bottom: 15px;
  background-color: transparent;
    padding-bottom: 7px;
    padding-top: 9px;
}
.cr-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 3px;
}
.cr-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
  margin-top: -6px;
}
.cr-slider:focus {
  outline: none;
}
/*
.cr-slider:focus::-webkit-slider-runnable-track {
    background: #ccc;
}
*/

.cr-slider::-moz-range-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 3px;
}
.cr-slider::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
  margin-top: -6px;
}

/*hide the outline behind the border*/
.cr-slider:-moz-focusring{
  outline: 1px solid white;
  outline-offset: -1px;
}

.cr-slider::-ms-track {
  width: 300px;
  height: 5px;
  background: transparent;/*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  border-color: transparent;/*leave room for the larger thumb to overflow with a transparent border */
  border-width: 6px 0;
  color: transparent;/*remove default tick marks*/
}
.cr-slider::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.cr-slider::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.cr-slider::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
}
.cr-slider:focus::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.5);
}
.cr-slider:focus::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.5);
}
