

/* VIDEO ASPECT WRAPPER */
.videoWrapper {
    position: relative;
    padding-bottom: 53%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.loader {
    @extend .fa;
    @extend .fa-spin;
    @extend .fa-fw;
    @extend .fa-circle-o-notch;
}

.nobr {
    white-space: nowrap !important;
}

.ellipsis {
    text-overflow: ellipsis; /* enables ellipsis */
    white-space: nowrap; /* keeps the text in a single line */
    overflow: hidden; /* keeps the element from overflowing its parent */
}

.btn--next-step{
    padding-right: 30px;
}
.next-step-indicator {
    -webkit-animation-name: scale;
    animation-name: scale;
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;

    position: absolute;
    padding-left:5px;
}

.label-as-badge {
    border-radius: 1em;
    padding: 4px 8px;
}


.logo{
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-weight: 400 !important;
}
