
.draggable--original {
    display: none !important;
}

.draggable-mirror {
    @include box-shadow(0px 0 14px 2px transparentize(darken($color5, 30%), .5));

}
.draggable-source--is-dragging{
    opacity: 0.5;
}

.drag-handle{
    margin: -10px 0 -10px -10px;
    cursor: move;
    padding:10px;
    color: #aaaaaa;
}
