/*
 * Start Bootstrap - Landing Page (http://startbootstrap.com/)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */

.topnav {
  font-size: 14px;
}

.lead {
  font-size: 18px;
  font-weight: 400;
}

.intro-header {
  padding-top: $navbar-height;
  padding-bottom: 50px;
  text-align: center;
  color: #f8f8f8;
 // background: url(/theme/image/homepage) no-repeat center center;
  background-size: cover;
  background-color: $color5;
}

.intro-message {
  position: relative;
  padding-top: 5%;
  padding-bottom: 5%;

  > .logo{
      font-size: 5em;
      margin: 0;
      text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
  }
}

.intro-message > h1 {
  margin: 0;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
  font-size: 18px;
}

.intro-divider {
  width: 400px;
  border-top: 1px solid #f8f8f8;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.intro-message > h4 {
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
}

@media(max-width: 767px) {
  .intro-message {
    padding-bottom: 15%;
  }

  .intro-message > .logo {
    font-size: 3em;
  }

  ul.intro-social-buttons > li {
    display: block;
    margin-bottom: 20px;
    padding: 0;
  }

  ul.intro-social-buttons > li:last-child {
    margin-bottom: 0;
  }

  .intro-divider {
    width: 100%;
  }

    .hp_banner h2 {
        margin: 0;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
        font-size: 3em;
    }

    .list-auth-buttons li {
        display: block;
    }
/*
    // smaller background image
    .intro-header {
        background: url(/theme/image/homepage-max-768) no-repeat center center;
        background-size: cover;
    }*/
}

.network-name {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 2px;
}

.content-section-a {
  padding: 50px 0 30px;
  background-color: #f8f8f8;
}

.content-section-b {
  padding: 50px 0 30px;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  background-color: #ffffff;
}

.section-heading {
  margin-bottom: 30px;
}

.section-heading-spacer {
  float: left;
  width: 200px;
  border-top: 3px solid #e7e7e7;
}

.hp_banner {
  padding: 4em 0;
  color: #f8f8f8;
  background-color: $color4;

  & h2 {
    margin: 0;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
    font-size: 3em;
  }

  & ul {
    margin-bottom: 0;
  }
}
