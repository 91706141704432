
.footer {
  padding: 10px 0 0;
  background-color: $gray-lighter;
  color: $gray-light;
  //font-size: .9em;
  border: 1px solid $well-border;
  @include box-shadow(inset 0 1px 10px rgba(0,0,0,.05));

  width: 100%;
  & a{
    color: $gray;
    transition: all linear .2s;
    &:hover {
      color: $color5;
    }
  }

  ul.list-unstyled {
    & li.list-header{
      font-weight: bold;
      font-size: 1.2em;
      padding: 30px 0 20px 0;
      color: $gray-darker;
    }
    & li {
      padding: 6px 35px 6px 0;

      @media (max-width: $screen-xs-min) {
       // max-width: 91px;
      }
      & a {
        color: $gray;
      }
      &.active a {
        font-weight: bold;
        color: $gray-darker;
      }
    }
  }

    .list, .list-inline{
        & li.list-header{
            font-weight: bold;
            font-size: 1.2em;
            padding: 25px 20px 15px 0;
            color: $gray-darker;
        }
        margin-top: 5px;
        margin-bottom: 20px;
    }
}

// on sm screens and smaller, footer doesn't need to be bottom aligned.
// there is content enough to push it down the screen
// and on smaller screen the footer get higher than $footer-height due to text wrapping
// which causes the footer going over the content
/*
@media (min-width: $screen-sm-min) and (min-height: 700px) {
  .footer{
    position: absolute;
    bottom: 0;
  }
  .main{
    margin-bottom: $footer-height;
  }
}
*/
