
.panel-light {
  padding: 12px 15px 15px;

  @include panel-variant(#fff, $panel-default-text, #fff, #fff);

  & > .panel-heading, .panel-body, .panel-footer {
    padding: 0 0 10px 0;
  }
  & > .panel-heading {
    font-weight:bold;
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: 15px;
  }
}

.panel-body--special-list-overview {
  padding: 5px;
  display: flex;
    justify-content: center;
    overflow-x: hidden;
    min-height: 131px;
    align-items: center;
}

.panel-profile-badge {
  padding: 0 0 0 0;

}

.panel-stats {
  @media (min-width: $screen-sm-min) {
    min-height: 130px;
  }

  & h2 small {
      font-size: 50%;
      display: block;
  }
  & .panel-body {
    padding-top:0;
    & .progress{
      margin-bottom: 0;
    }
  }
}

//http://bootsnipp.com/snippets/featured/panels-with-nav-tabs
.panel--with-nav-tabs {
   & .panel-heading{
     padding-bottom: 0;
  }
  & .panel-title{
    padding-bottom: 1em;
  }
  & .nav-tabs{
    border-bottom: none;
  }
  & .nav-justified{
    margin-bottom: -1px;
  }

  & .nav-tabs > li > a,
  & .nav-tabs > li > a:hover,
  & .nav-tabs > li > a:focus {
    color: $gray-dark;
    background-color: $gray-lighter;
  }

  & .nav-tabs > .open > a,
  & .nav-tabs > .open > a:hover,
  & .nav-tabs > .open > a:focus,
  & .nav-tabs > li > a:hover,
  & .nav-tabs > li > a:focus {
    color: $gray-dark;
    background-color: $gray-light;
    border-color: transparent;
  }

  & .nav-tabs > li.active > a,
  & .nav-tabs > li.active > a:hover,
  & .nav-tabs > li.active > a:focus {
    color: #555;
    background-color: #fff;
    border-color: #ddd;
    border-bottom-color: transparent;
  }
}
