.message-list {

    padding-left: 0;
    list-style: none;

    &item {
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        margin-bottom: 1.5rem !important;

        & .message-wrapper {
            flex: 1;

            margin-right: 20%;

            & .message-footer {

            }
        }

        & .message-avatar .avatar {
            width: 42px;
            max-height: 42px;
            min-height: 42px;
            min-width: 42px;
            font-size: 1.6em;
            line-height: 0.9em;
            margin: 0 1em;

        }

        & .message-balloon {
            background-color: #f5f8fa;
            position: relative;
            padding: 10px 15px;
            border-radius: 9px;

            & a {
                color: inherit;
                text-decoration: underline;
            }

            &:first-child:before {
                position: absolute;
                top: 16px;
                left: -8px;
                content: '';
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 2px;
                vertical-align: middle;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-right: 6px solid #f5f8fa;
            }

            & .message-date {
                font-size: 80%;
            }

            & .message-date, .messsage-read-confirmation {
                color: $text-muted;
            }
        }

        &--participant {
            & .message-avatar .avatar {
                margin-left: 0;
            }
        }

        &--viewer {
            & .message-wrapper {

                margin-right: 0;
                margin-left: 20%;
            }

            & .message-avatar .avatar {
                margin-right: 0;
            }

            & .message-balloon {
                color: #fff;
                background-color: $color5;
                position: relative;
                padding: 10px 15px;
                border-radius: 9px;

                &:first-child:before {
                    left: auto;
                    right: -8px;
                    margin-left: 0;
                    margin-right: 2px;
                    border-right: 0;
                    border-left: 6px solid $color5;
                }

                & .message-date, .messsage-read-confirmation {
                    color: transparentize(#fff, 0.4);
                }

            }
        }
    }

    &attachments {

        margin-bottom: 1.5rem !important;
        & a {
            @extend .badge;
            padding: 4px 7px
        }
    }
}

.message-preview {
    color: $gray-light;
    margin-bottom: 5px;
    min-height: 20px;
}

.panel > .list-group--inbox {
    & .list-group-item-heading {
        word-break: break-all;
    }
    
    & .list-group-item:first-child {
        border-top: 0;
        margin-top: 20px;
    }
}
