// make the search box wider
@media (min-width: $grid-float-breakpoint) {
    .navbar-search {
        min-width: 300px;
        width: 40vw;
        max-width: 500px;

        & .form-control {
            width: 100% !important;
        }
    }
}

span.twitter-typeahead {
    .list-group {
        width: 100%;

        // add shadow
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        //  bottom border should be rounded for shadow
        @include border-bottom-radius($list-group-border-radius);

        //max height
        max-height: 50vh;
        overflow-y: auto;
    }

    .list-group-item {
        // using up and down arrows should also highlight a list-group-item
        &.tt-cursor {
            @extend .list-group-item:hover;
        }

        // top item should fit search box smoothly
        &:first-child {
            @include border-top-radius(0);
        }
    }
}

.input-group {
    span.twitter-typeahead {
        display: block !important;
        height: 34px;

        .tt-menu, .tt-dropdown-menu {
            top: 32px !important;
        }

        &:not(:first-child):not(:last-child) .form-control {
            border-radius: 0;
        }
    }
}

.search_image {
    @include img-responsive;
    border-radius: 50%;
    max-height: 45px;
    padding: 0;
    margin-right: 8px;
    display: inline-block;
}
