.cap {
    $c: &;
    max-width: 120px;
    min-width: 120px;
    min-height: 165px;
    display: inline-block;
    margin-bottom: 4px;
    vertical-align: top;
    text-align: left;
    padding: 10px;
    border: 0; //1px solid $gray-lighter;
    border-radius: 5px;

    //huitieme 24mm ipv 30mm
    &--size8 {
        #{$c}__image, #{$c}__image_invalid, #{$c}__image_big_invalid, .cap_image {
            width: 80%;
            height: 80%;
        }
    }

    //quart 26mm ipv 30mm
    &--size1 {
        #{$c}__image, #{$c}__image_invalid, #{$c}__image_big_invalid, .cap_image {
            width: 85%;
            height: 85%;
        }
    }

    // cuvee special 28mm ipv 30mm
    &--size2 {
        #{$c}__image, #{$c}__image_invalid, #{$c}__image_big_invalid, .cap_image {
            width: 90%;
            height: 90%;
        }
    }

    &__producer {
        text-align: center;
        font-size: 0.9em;
    }

    &__image_container {
        position: relative;
        min-height: 100px;
    }

    &__image, &__image_invalid {
        border-radius: 50%;
        @include img-responsive;
        //max-width: 100%;
        //height: auto;

        // auto center image in container vertical and horizontal
        max-height: 100%;
        width: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    &__image_big_invalid {
        border-radius: 50%;
        @include img-responsive;
        border-radius: 50%;
        position: relative;
    }

    &__number {
        float: left;
        font-weight: bold;
        padding-left: 3px;
    }

    &__value {
        float: right;
        padding-right: 3px;
    }

    &__note {
        float: right;
        padding-right: 6px;
        color: $alert-info-text;

        &--todo {
            color: $alert-danger-text;
        }
    }

    &__title {
        font-size: 0.8em;
        text-align: center;
        clear: both;
    }

    &__list-amount {
        @extend .label;
        @extend .label-as-badge;
        @include label-variant(#000);//$label-default-bg);
        box-shadow: 0 1px 1px darken(#ccc, 3%), -1px 1px 1px darken(#ccc, 6%);
        position: absolute;
    }

    &--no-reference {
        color: $gray-light;
    }

    &--toolbar.btn-group-justified {
        & .btn:not(.btn-first) {
            // remove double borders for justified buttongroup
            //  &:not(:first-child) {
            border-left: 0;
            //}
        }
    }

    &--toolbar {
        margin: 0.5em 0;

        & .btn {
            color: $gray;

            &:hover {
                color: #333;
            }
        }

        & .btn-success, & .btn-doubles-success, & .btn-wish-success, & .btn-lists-success {
            color: white;

            &:hover {
                color: white;
            }
        }

        & .btn-group-justified {
            margin: 50px;
        }
    }

    // when linked using anchors
    // eg when using the search
    /*&:target {
      animation: highlight 7s ease;
    }*/

    &--is-invalid &__title {
        text-decoration: line-through;
    }

    &--is-invalid &__title, &--is-invalid &__number, &--is-invalid &__value {
        color: $text-muted;
    }

    // invalid caps are not shown completely
    &--is-invalid &__image, &__image_big_invalid {
        opacity: 0.3;
        filter: alpha(opacity=30); /* For IE8 and earlier */

        transition: opacity;
        transition-duration: 500ms;
        transition-timing-function: ease-in-out;

    }

    &--is-invalid:hover &__image, &__image_big_invalid:hover {
        opacity: 1;
        filter: alpha(opacity=100); /* For IE8 and earlier */
    }

    &--overlap {
        min-height: auto;
        margin-left: -60px;
        margin-bottom: 0;

        &:nth-of-type(1) {
            margin-left: 0;
        }
    }

    &--divider {
        display: inline-block;
        padding: 108px 20px 0;
        @extend .text-muted;
    }
}

// Danger and error appear as red
.btn-doubles-success {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

.btn-wish-success {
    @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

.btn-lists-success {
    @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

@include list-group-item-variant(success, transparentize($btn-info-bg, 0.5), $state-success-text);

.cap_image {
    border: 0;
    background-color: transparent;
    padding: 0 4px;
    transition: all .2s ease-in-out;
    max-width: 100%;
    display: inline-block;
    height: auto;
    border-radius: 50%;

    &--note_overview {
        height: 50px;
        padding-right: 15px;
    }


}

.cap_invalid_detail {
    text-decoration: line-through;
    color: $gray;
}
