//
// Breadcrumbs
// --------------------------------------------------

$breadcrumb-active-color: transparentize($color5, 0.6);
$breadcrumb-color: transparentize($gray-darker, 0.5);

.breadcrumb {
  padding: $breadcrumb-padding-vertical/2 $breadcrumb-padding-horizontal $breadcrumb-padding-vertical/2 0;
  margin-bottom: $line-height-computed;
  list-style: none;
  //background-color: $breadcrumb-bg;
  //border-radius: $border-radius-base;

  > li {
    display: inline-block;

    + li:before {
      // [converter] Workaround for https://github.com/sass/libsass/issues/1115
      $nbsp: "\00a0";
      $breadcrumb-separator: '\203A'; // &rsaquo;

      content: "#{$breadcrumb-separator}#{$nbsp}"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 0 0 5px;
      color: $breadcrumb-active-color;
    }

    a{
      color: $breadcrumb-color;
    }
  }

  > .active {
    //color: $breadcrumb-active-color;
    color: $color5;
  }
}
